function heroVideoAspectRatio() {
    if (jQuery('.main-hero').length) {
        var viewport_ratio = jQuery(window).width() / jQuery(window).height();
        var ratio = 1.777777777777778;// 16:9 ratio
        
        if (viewport_ratio < ratio) {//check if its below 16/9
            jQuery('.main-hero').addClass('lt-16-9');//less than 16:9 ratio class
            
            //resize iframe to fill space
            if(jQuery('.main-hero .video-bg iframe').length){
                var win_ht = jQuery(window).height();
                
                jQuery('.main-hero .video-bg iframe').css({
                    'height': win_ht,
                    'width': win_ht * ratio
                });
            } 
        } else {
            jQuery('.main-hero').removeClass('lt-16-9');
            
            //resize iframe to fill space
            if(jQuery('.main-hero .video-bg iframe').length){   
                jQuery('.main-hero .video-bg iframe').css({
                    'height': '',
                    'width': ''
                });
            }
        }
    }
}

jQuery(document).ready(function ($) {
    heroVideoAspectRatio();

    var scrollTimer;
    $(window).on('scroll', function () {
        clearTimeout(scrollTimer);
        scrollTimer = setTimeout(function () {
            var y = $(this).scrollTop();

            if (y >= 100) {
                if (!$('body').hasClass('scrolled')) {
                    $('body').addClass('scrolled');
                }
            } else {
                $('body').removeClass('scrolled');
            }

            if (y >= 700 && $(document).height() > 700) {
                if (!$('body').hasClass('full-scrolled')) {
                    $('body').addClass('full-scrolled');
                }
            } else {
                $('body').removeClass('full-scrolled');
            }
            
        }, 10);
    });

    var resizeTimer;
    $(window).on('resize', function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            heroVideoAspectRatio();
        }, 250);
    });
}); 

//For lazy loading css background images set via a field in the backend. Adds a css background image
document.addEventListener("DOMContentLoaded", function() {
    let lazyDataBg = [].slice.call(document.querySelectorAll("[data-bgimg]"));
    
    if ("IntersectionObserver" in window && "IntersectionObserverEntry" in window && "intersectionRatio" in window.IntersectionObserverEntry.prototype) {
        let lazyBackgroundObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) { 
                    entry.target.style.cssText = 'background-image: url(' + entry.target.dataset.bgimg + ');';
                    lazyBackgroundObserver.unobserve(entry.target);
                }
            });
        });

        lazyDataBg.forEach(function(lazyBackground) {
            lazyBackgroundObserver.observe(lazyBackground);
        });
    }
});

jQuery(window).on('load', function () {
    AOS.init({
        once: true
    });
});